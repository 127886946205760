<template>
  <div>
    <vue-simple-suggest
      @select="emitSelectedSchoolId"
      @blur="pickerBlur"
      type="search"
      mode="select"
      ref="schoolPicker"
      :list="search"
      :styles="{
        vueSimpleSuggest: '',
        inputWrapper: '',
        defaultInput: 'form-control ' + inputClass,
        suggestions: '',
        suggestItem: '',
      }"
      :destyled="true"
      display-attribute="name"
      value-attribute="id"
    >
      <template slot="suggestion-item" slot-scope="{ suggestion, query }">
        <div v-if="!suggestion.createNewSchool">
          {{ suggestion.name }}
          <small v-if="suggestion.address && suggestion.address.city"
            >i {{ suggestion.address.city }}</small
          >
        </div>
        <div v-if="suggestion.createNewSchool">
          Ny skola: {{ suggestion.name }}
        </div>
      </template>
    </vue-simple-suggest>
  </div>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import Fuse from "fuse.js";
import find from "lodash/find";
import "vue-simple-suggest/dist/styles.css";

const uuidv4 = require("uuid/v4");

export default {
  components: {
    VueSimpleSuggest,
  },
  props: {
    schools: Array,
    schoolId: undefined,
    inputClass: String,
  },
  data() {
    return {
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "",
        defaultInput: "form-control",
      },
      fuse: Object,
      selectedSchool: {},
      editingSchool: false,
    };
  },
  methods: {
    search(query) {
      const result = this.fuse.search(query);
      const school = {
        name: `${query}`,
        id: uuidv4(),
        new: true,
        address: { city: "" },
        beeingEdited: false,
        createNewSchool: true,
      };
      if (result.length >= 10) {
        return result.slice(0, 8).concat(school);
      }
      return result.concat([school]);
    },
    emitSelectedSchoolId() {
      const selectedSchool = this.$refs.schoolPicker.selected;
      if (selectedSchool) {
        if (selectedSchool.createNewSchool && !this.selectedSchool.new) {
          this.emitNewSchool(selectedSchool);
        } else if (
          selectedSchool.createNewSchool &&
          this.selectedSchool.new &&
          this.editingSchool
        ) {
          this.selectedSchool.name = selectedSchool.name;
          return;
        }

        if (
          this.selectedSchool.new &&
          this.editingSchool &&
          this.selectedSchool.beeingEdited
        ) {
          this.editingSchool = false;
          this.selectedSchool.beeingEdited = false;
        }

        if (selectedSchool.new && !selectedSchool.beeingEdited) {
          this.editingSchool = true;
          selectedSchool.beeingEdited = true;
        }

        this.selectedSchool = selectedSchool;
        this.emitSchoolId(selectedSchool);
      }
    },
    emitNewSchool(school) {
      this.editingSchool = true;
      school.createNewSchool = false;
      this.$emit("newSchool", school);
    },
    emitSchoolId(school) {
      this.$emit("select", school.id);
    },
    pickerBlur() {
      if (
        this.selectedSchool.id !== undefined &&
        this.selectedSchool.new &&
        this.editingSchool
      ) {
        this.selectedSchool.name = this.$refs.schoolPicker.text;
      } else if (this.selectedSchool.id === undefined) {
        this.$refs.schoolPicker.setText("");
      } else {
        this.$refs.schoolPicker.setText(this.selectedSchool.name);
      }
    },
  },
  computed: {
    selected() {
      return find(this.schools, { id: this.schoolId });
    },
  },
  created() {
    this.fuse = new Fuse(this.schools, {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["name", "address.city"],
    });
  },
};
</script>
`

<style>
.vue-simple-suggest > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.vue-simple-suggest {
  position: relative;
}

.vue-simple-suggest,
.vue-simple-suggest * {
  box-sizing: border-box;
}

.suggestions {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  top: calc(100% + 5px);
  border-radius: 3px;
  border: 1px solid #aaa;
  background-color: #fff;
  opacity: 1;
  z-index: 1000;
}

.suggestions .suggest-item {
  cursor: pointer;
  user-select: none;
}

.suggestions .suggest-item,
.suggestions .misc-item {
  padding: 5px 10px;
}

.suggestions .suggest-item.hover {
  background-color: #2874d5 !important;
  color: #fff !important;
}

.suggestions .suggest-item.selected {
  background-color: #2832d5;
  color: #fff;
}
</style>
