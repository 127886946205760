<template>
  <div>
    <vue-countdown
      :time="initialTimeLeft"
      :auto-start="true"
      ref="countdown"
      :interval="1000"
    >
      <template slot-scope="props">
        <h1>
          {{ (props.days + "").padStart(3, "0") }}:{{
            (props.hours + "").padStart(2, "0")
          }}:{{ (props.minutes + "").padStart(2, "0") }}:{{
            (props.seconds + "").padStart(2, "0")
          }}
        </h1>
      </template>
    </vue-countdown>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  components: {
    VueCountdown,
  },
  props: {
    date: String,
  },
  data() {
    return {
      initialTimeLeft: 0,
    };
  },
  mounted() {
    const now = Date.now;
    this.initialTimeLeft = this.parsedDate - now();
  },
  computed: {
    parsedDate() {
      return Date.parse(this.date);
    },
  },
};
</script>

<style></style>
