var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-simple-suggest',{ref:"schoolPicker",attrs:{"type":"search","mode":"select","list":_vm.search,"styles":{
      vueSimpleSuggest: '',
      inputWrapper: '',
      defaultInput: 'form-control ' + _vm.inputClass,
      suggestions: '',
      suggestItem: '',
    },"destyled":true,"display-attribute":"name","value-attribute":"id"},on:{"select":_vm.emitSelectedSchoolId,"blur":_vm.pickerBlur},scopedSlots:_vm._u([{key:"suggestion-item",fn:function({ suggestion, query }){return [(!suggestion.createNewSchool)?_c('div',[_vm._v("\n        "+_vm._s(suggestion.name)+"\n        "),(suggestion.address && suggestion.address.city)?_c('small',[_vm._v("i "+_vm._s(suggestion.address.city))]):_vm._e()]):_vm._e(),_vm._v(" "),(suggestion.createNewSchool)?_c('div',[_vm._v("\n        Ny skola: "+_vm._s(suggestion.name)+"\n      ")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }