<template>
  <div>
    <input
      type="file"
      id="projectFileInput"
      name="projectFileInput"
      accept="application/pdf"
      @change="setFile"
      ref="fileInput"
    />
    <div class="small text-danger" v-if="!validType">
      Det går endast att ladda upp filer i PDF-format. Word eller dylikt går ej.
    </div>
    <div class="small text-danger" v-if="!validName">
      Första tecknet i ditt filanamn måste vara en bokstav eller siffra.
    </div>
  </div>
</template>

<script>
import { DirectUpload } from "activestorage";

export default {
  components: {},

  props: {
    directUploadUrl: String,
    signedId: String,
  },
  data() {
    return {
      file: Object, 
      isValid: true,
      validType: true,
      validName: true,
    };
  },
  methods: {
    setFile(event) {
      const file = event.target.files[0];
      const validType = this.isPdf(file);
      const validName = (/^[1-9a-zA-ZåäöÅÄÖ].*/.test(file.name));
      const isValid = validType && validName;
      this.validType = validType;
      this.validName = validName;
      this.isValid = isValid;
      if (isValid) {
        this.file = event.target.files[0];
        this.uploadFile(this.file);
      } else {
        this.$refs.fileInput.value = "";
      }
    },
    uploadFile(file) {
      const upload = new DirectUpload(
        this.file,
        "/rails/active_storage/direct_uploads"
      );
      upload.create((error, blob) => {
        if (error) {
          console.log(error);
        }
        const { signed_id } = blob;
        this.$emit("update:signedId", signed_id);
        this.$emit("blob-uploaded", blob);
      });
    },
    isPdf(file) {
      return file.type === "application/pdf";
    },
  },
  created() {},
};
</script>
