<template>
  <div>
    <div
      class="form-check"
      v-for="option in options"
      :key="option[valueAttribute]"
    >
      <input
        class="form-check-input"
        type="checkbox"
        :value="value.includes(option[valueAttribute])"
        :id="`hdyhaur-${option[valueAttribute]}-${uuid}`"
        @change="setSelected($event, option[valueAttribute])"
      />
      <label
        class="form-check-label"
        :for="`hdyhaur-${option[valueAttribute]}-${uuid}`"
        >{{ option[displayAttribute] }}</label
      >
    </div>
  </div>
</template>

<script>
import { pull, concat } from "lodash";

const uuidv4 = require("uuid/v4");

export default {
  props: {
    options: Array,
    valueAttribute: String,
    displayAttribute: String,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uuid: uuidv4(),
    };
  },
  methods: {
    setSelected(evt, option) {
      let newSelected;
      if (evt.target.checked) {
        newSelected = concat(this.value, option);
      } else {
        newSelected = pull(this.value, option);
      }
      this.$emit("input", newSelected);
    },
  },
};
</script>
